<template>
  <div>
    <c-table
      ref="table"
      title="중장비 목록"
      :columns="gridHeavyEquip.columns"
      :gridHeight="gridHeavyEquip.height"
      :data="workPermit.heavyEquips"
      :isTitle="true"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&isWriting"
      gridHeightAuto
      selection="multiple"
      rowKey="sopWorkPermitHeavyEquipmentId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 추가 -->
          <c-btn v-if="editable&&isWriting" label="추가" icon="add" @btnClicked="addHeavyEquip" />
          <!-- 제외 -->
          <c-btn v-if="editable&&isWriting" label="제외" icon="remove" @btnClicked="removeHeavyEquip" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'swpHeavyEquip',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
          lotos: [],
          deleteHeavyEquipss: [],
          heavyEquips: [],
          deleteHeavyEquips: [],
        }
      }
    },
    gridHeavyEquip: {
      type: Object,
      default: function() {
        return {
          columns: [
            {
              required: true,
              name: 'heavyEquipmentName',
              field: 'heavyEquipmentName',
              label: '중장비명', // 중장비명
              align: 'left',
              style: 'width:150px',
              sortable: true,
              type: 'text',
            },
            {
              name: 'amount',
              field: 'amount',
              label: '수량', // 수량
              align: 'right',
              style: 'width:60px',
              sortable: true,
              type: 'number',
            },
            {
              name: 'remarks',
              field: 'remarks',
              label: '비고', // 비고
              align: 'left',
              style: 'width:150px',
              sortable: true,
              type: 'textarea',
            },
          ],
          height: '350px'
        };
      },
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    height() {
      this.setTabHeight();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.setTabHeight();
    },
    addHeavyEquip() {
      this.workPermit.heavyEquips.splice(0, 0, {
        sopWorkPermitHeavyEquipmentId: uid(),  // 작업허가서 중장비 일련번호
        sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
        heavyEquipmentName: '',  // 중장비명
        amount: '',  // 수량
        remarks: '',  // 비고
        regUserId: this.$store.getters.user.userId,  // 수정자 ID
        editFlag: 'C',
      })
    },
    removeHeavyEquip() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000120', // 삭제할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.workPermit.deleteHeavyEquips) {
            this.workPermit.deleteHeavyEquips = [];
          }
          if (item.editFlag !== 'C') {
            this.workPermit.deleteHeavyEquips.push(item)
          }
          this.workPermit.heavyEquips = this.$_.reject(this.workPermit.heavyEquips, item);
        });
      }
    },
    setTabHeight() {
      let returnText = '500px';
      returnText = this.height && !isNaN(Number(this.height.replace('px', ''))) ? String(Number(this.height.replace('px', '')) - 70) + 'px' : returnText;
      this.gridHeavyEquip.height = returnText;
    },
  }
};
</script>
